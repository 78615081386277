import { createSlice } from '@reduxjs/toolkit';


// status: 'not_loaded', 'loading', 'loaded', 'error'
const initialState = {
  isLogin: false,
  currentUser: {},
  userStatus: 'not_loaded',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload.currentUser;
      state.isLogin = action.payload.isLogin;
      state.userStatus = action.payload.userStatus;
    },
  },
});

export const { setCurrentUser } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
