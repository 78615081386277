/* eslint-disable */
import {lazy} from 'react';
import {DEFAULT_PATHS} from 'config.js';
import {USER_ROLE} from "./constants";

const dashboards = {
    dashboard: lazy(() => import('views/dashboards/Dashboard')),
};
const courses = {
    manageCourses: lazy(() => import('views/courses/ManageCourses')),
    manageCourse: lazy(() => import('views/courses/ManageCourse')),
    viewCourse: lazy(() => import('views/courses/ViewCourse')),
    // detail: lazy(() => import('views/courses/CoursesDetail')),
    // uploadCourses: lazy(() => import('views/courses/CourseUploadCourses'))
};
const quiz = {
    list: lazy(() => import('views/quiz/QuizList')),
    detail: lazy(() => import('views/quiz/QuizDetail')),
    result: lazy(() => import('views/quiz/QuizResult')),
};
const paths = {
    list: lazy(() => import('views/paths/PathsList')),
    detail: lazy(() => import('views/paths/PathsDetail')),
};

const instructor = {
    list: lazy(() => import('views/instructor/InstructorList')),
    detail: lazy(() => import('views/instructor/InstructorDetail')),
};
const misc = {
    player: lazy(() => import('views/misc/Player')),
    material: lazy(() => import('views/misc/Material')),
    syllabus: lazy(() => import('views/misc/Syllabus')),
};
const student = {
    list: lazy(() => import('views/student/StudentList')),
    detail: lazy(() => import('views/student/StudentDetail')),
};
const manageUsers = {
    manageUsers: lazy(() => import('views/manage-users/ManageUsers'))
}
const assetTracking = {
    assetTracking: lazy(() => import('views/asset-tracking/AssetTracking'))
}
const manageSchools = {
    manageSchools: lazy(() => import('views/manage-schools/ManageSchools'))
}
const classrooms = {
    classrooms: lazy(() => import('views/classrooms/Classrooms'))
}

const analytics = {
    analytics: lazy(() => import('views/analytics/Analytics'))
}

const userSettings = {
    userSettings: lazy(() => import('views/user-settings/UserSettings'))
}

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
    mainMenuItems: [
        {
            path: DEFAULT_PATHS.APP,
            exact: true,
            redirect: true,
            to: `${appRoot}/dashboard`,
            roles: [USER_ROLE.Admin, USER_ROLE.Educator, USER_ROLE.Student]
        },
        {
            path: `${appRoot}/dashboard`,
            icon: 'home-garage',
            label: 'menu.dashboard',
            exact: true,
            component: dashboards.dashboard,
            roles: [USER_ROLE.Admin, USER_ROLE.Educator, USER_ROLE.Student],
        },
        {
            path: `${appRoot}/manage-users`,
            label: 'menu.manageUsers',
            icon: 'user',
            exact: true,
            component: manageUsers.manageUsers,
            roles: [USER_ROLE.Admin],
        },
        {
            path: `${appRoot}/asset-tracking`,
            label: 'menu.assetTracking',
            icon: 'compass',
            exact: true,
            component: assetTracking.assetTracking,
            roles: [USER_ROLE.Admin],
        },
        {
            path: `${appRoot}/manage-schools`,
            label: 'menu.manageSchools',
            icon: 'graduation',
            exact: true,
            component: manageSchools.manageSchools,
            roles: [USER_ROLE.Admin],
        },
        {
            path: `${appRoot}/manage-courses`,
            label: 'menu.manageCourses',
            icon: 'online-class',
            exact: true,
            component: courses.manageCourses,
            roles: [USER_ROLE.Admin, USER_ROLE.Educator],
        },
        {
            path: `${appRoot}/manage-course`,
            exact: true,
            component: courses.manageCourse,
            roles: [USER_ROLE.Admin, USER_ROLE.Educator],
        },
        {
            path: `${appRoot}/view-course`,
            exact: true,
            component: courses.viewCourse,
            roles: [USER_ROLE.Admin, USER_ROLE.Educator, USER_ROLE.Student],
        },
        // {
        //     path: `${appRoot}/manage-courses`,
        //     label: 'menu.manageCourses',
        //     icon: 'online-class',
        //     exact: true,
        //     redirect: true,
        //     to: `${appRoot}/manage-courses/list`,
        //     roles: [USER_ROLE.Admin, USER_ROLE.Educator],
        //     subs: [
        //         {path: '/list', label: 'menu.list', component: courses.manageCourses},
        //         {path: '/detail', label: 'menu.detail', component: courses.detail},
        //         {
        //             path: `/upload-course`,
        //             label: 'menu.uploadCourses',
        //             component: courses.uploadCourses,
        //             roles: [USER_ROLE.Admin],
        //         },
        //     ],
        // },
        {
            path: `${appRoot}/classrooms`,
            label: 'menu.classrooms',
            icon: 'lecture',
            exact: true,
            component: classrooms.classrooms,
            roles: [USER_ROLE.Admin, USER_ROLE.Educator, USER_ROLE.Student],
        },
        {
            path: `${appRoot}/analytics`,
            label: 'menu.analytics',
            icon: 'chart-4',
            exact: true,
            component: analytics.analytics,
            roles: [USER_ROLE.Admin],
        },
        {
            path: `${appRoot}/user-settings`,
            exact: true,
            component: userSettings.userSettings,
        },
        // {
        //     path: `${appRoot}/quiz`,
        //     label: 'menu.quiz',
        //     icon: 'quiz',
        //     exact: true,
        //     redirect: true,
        //     to: `${appRoot}/quiz/list`,
        //     subs: [
        //         {path: '/list', label: 'menu.list', component: quiz.list},
        //         {path: '/detail', label: 'menu.detail', component: quiz.detail},
        //         {path: '/result', label: 'menu.result', component: quiz.result},
        //     ],
        // },
        // {
        //     path: `${appRoot}/paths`,
        //     label: 'menu.paths',
        //     icon: 'destination',
        //     exact: true,
        //     redirect: true,
        //     to: `${appRoot}/paths/list`,
        //     subs: [
        //         {path: '/list', label: 'menu.list', component: paths.list},
        //         {path: '/detail', label: 'menu.detail', component: paths.detail},
        //     ],
        // },
        // {
        //     path: `${appRoot}/instructor`,
        //     label: 'menu.instructor',
        //     icon: 'lecture',
        //     exact: true,
        //     redirect: true,
        //     to: `${appRoot}/instructor/list`,
        //     subs: [
        //         {path: '/list', label: 'menu.list', component: instructor.list},
        //         {path: '/detail', label: 'menu.detail', component: instructor.detail},
        //     ],
        // },
        // {
        //     path: `${appRoot}/misc`,
        //     label: 'menu.misc',
        //     icon: 'layout-5',
        //     exact: true,
        //     redirect: true,
        //     to: `${appRoot}/misc/player`,
        //     subs: [
        //         {path: '/player', label: 'menu.player', component: misc.player},
        //         {path: '/material', label: 'menu.material', component: misc.material},
        //         {path: '/syllabus', label: 'menu.syllabus', component: misc.syllabus},
        //     ],
        // },
    ],
    sidebarItems: [],
};
export default routesAndMenuItems;
